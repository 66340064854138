import React from "react";

import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
} from "reactstrap";

import favicon from "assets/img/favicon2.png";

function DepthNavbar() {
  const [navbarColor] = React.useState("navbar-black");
  const [collapseOpen, setCollapseOpen] = React.useState(false);

  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            toggleCollapse(setCollapseOpen, false);
          }}
        />
      ) : null}
      <Navbar className={"fixed-top " + navbarColor} expand="lg" color="info">
        <Container>
          <div className="navbar-translate">
            <NavbarBrand
              href="/index"
              id="navbar-brand"
              style={{ fontSize: "1.5rem" }}
            >
              <h6 style={{ display: "flex", alignItems: "center", margin: 0 }}>
                <img
                  src={favicon}
                  alt="Logo"
                  style={{
                    background: "transparent",
                    height: "30px",
                    width: "auto",
                    marginRight: "10px",
                  }}
                />
              </h6>
            </NavbarBrand>
            <button
              className="navbar-toggler navbar-toggler"
              onClick={() => {
                toggleCollapse(setCollapseOpen, !collapseOpen);
              }}
              aria-expanded={collapseOpen}
              type="button"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse
            className="justify-content-end"
            isOpen={collapseOpen}
            navbar
          >
            <Nav navbar style={{ fontSize: "1.2rem" }}>
              <NavItem>
                <NavLink href="/index/page-header">
                  <p>Home</p>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/index/about-section">
                  <p>Who We Are</p>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  onClick={(e) => {
                    window.location.href = "/consulting/1";
                  }}
                >
                  <p>Consulting</p>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  onClick={(e) => {
                    window.location.href = "/shop";
                  }}
                >
                  <p>Store</p>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  onClick={(e) => {
                    window.location.href = "/question";
                  }}
                >
                  <p>Q&A</p>
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

function toggleCollapse(toggle, value) {
  document.documentElement.classList.toggle("nav-open");
  toggle(value);
}

export default DepthNavbar;
