import React from "react";

import { NavbarBrand, Navbar, Container } from "reactstrap";

import favicon from "assets/img/favicon2.png";

function AdminDepthNavbar2() {
  const [navbarColor] = React.useState("navbar-black");

  return (
    <>
      <Navbar className={"fixed-top " + navbarColor} expand="lg" color="info">
        <Container>
          <div className="navbar-translate">
            <NavbarBrand
              href="/admin/index"
              id="navbar-brand"
              style={{ fontSize: "1.5rem" }}
            >
              <h6 style={{ display: "flex", alignItems: "center", margin: 0 }}>
                <img
                  src={favicon}
                  alt="Logo"
                  style={{
                    background: "transparent",
                    height: "30px",
                    width: "auto",
                    marginRight: "10px",
                  }}
                />
              </h6>
            </NavbarBrand>
          </div>
        </Container>
      </Navbar>
    </>
  );
}

export default AdminDepthNavbar2;
